import { CheckCard, ProFormDatePicker, ProFormDigit, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../../hooks';
import { disableFutureDate } from '../../../../utils/date';


export const EditDealFormFields = () => {
  const { t } = useTranslation();
  const { isMobileWithTablet } = useIsMobile();

  return <>
    <ProFormText
      name="vin"
      label={t('common.vin')}
      placeholder={'WBSWD93549PY43XXX'}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <Row
      gutter={[16, 0]}
    >
      <Col xs={24} sm={8}>
        <ProFormText
          name="make"
          label={t('common.carMake')}
          placeholder={t('Tesla')}
          rules={[{ required: true, message: t('global.requiredField') }]}
        />
      </Col>
      <Col xs={24} sm={8}>
        <ProFormText
          name="model"
          label={t('common.carModel')}
          placeholder={t('Model Y')}
          rules={[{ required: true, message: t('global.requiredField') }]}
        />
      </Col>
      <Col xs={24} sm={8}>
        <ProFormText
          name="variant"
          label={t('common.variant')}
          placeholder={t('Performance')}
        />
      </Col>
    </Row>

    <ProFormSelect
      fieldProps={
        {
          'notFoundContent': t('common.noDataText'),
        }
      }
      name="year"
      label={t('common.year')}
      showSearch
      placeholder={new Date().getFullYear() + ''}

      options={[...Array(100).keys()].map(v => {
        const year = new Date().getFullYear() - v;

        return {
          value: year,
          label: year + '',
        };
      })}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <ProFormDatePicker
      name="registration"
      label={t('common.registration')}
      placeholder={'21-12-1999'}
      rules={[{ required: true, message: t('global.requiredField') }]}
      fieldProps={{
        format: 'DD-MM-YYYY',
        disabledDate: disableFutureDate,
        style: { width: '100%', background: 'white' },
      }}
    />

    <ProFormDigit
      name="mileage"
      min={0}
      fieldProps={{ precision: 0 }}
      label={t('common.mileage')}
      placeholder={'123000'}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <ProFormText
      name="color"
      label={t('common.color')}
      placeholder={'orange'}
    />

    <Form.Item
      label={t('common.gear')}
      name="gear"
      rules={[{ required: true, message: t('global.requiredField') }]}
    >
      <CheckCard.Group
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0 auto',
          maxWidth: '512px',
        }}
      >
        <Row style={{ width: '100%' }} gutter={[16, 0]}>
          {
            Object.entries(t('common.gearTypes', {
              returnObjects: true,
            })).map(([key, value]) => (
              <Col style={{ paddingLeft: isMobileWithTablet ? 0 : 16 }} xs={24} sm={12}>
                <CheckCard
                  style={{
                    width: '100%',
                  }}
                  title={value}
                  value={key}
                />
              </Col>

            ))}
        </Row>

      </CheckCard.Group>
    </Form.Item>

    <Form.Item
      label={t('common.fuel')}
      name="fuel"
      rules={[{ required: true, message: t('global.requiredField') }]}
    >
      <CheckCard.Group
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0 auto',
          maxWidth: '512px',
        }}
      >
        <Row style={{ width: '100%' }} gutter={[16, 0]}>
          {
            Object.entries(t('common.fuelTypes', {
              returnObjects: true,
            })).map(([key, value]) => (
              <Col style={{ paddingLeft: isMobileWithTablet ? 0 : 16 }} xs={24} sm={12}>
                <CheckCard
                  style={{
                    width: '100%',
                  }}
                  title={value}
                  value={key}
                />
              </Col>

            ))}
        </Row>

      </CheckCard.Group>
    </Form.Item>
  </>;

};