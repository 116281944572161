import { HttpError } from '@refinedev/core';
import { useState } from 'react';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { useTable } from '@refinedev/antd';
import { Offer } from './vo/offers.dto';
import { OffersAdminTable } from './components/offer-list.component';

export const OffersAdminPage = () => {
  useDocumentTitle({ i18nKey: 'page.admin.deal.list.pageMetaTitle' });

  const [search, setSearch] = useState('');

  const tableData = useTable<Offer, HttpError, { search: string }>({
    resource: 'offer',
    pagination: {
      mode: 'server',
      pageSize: 20,
    },
    meta: {
      search: search.trim(),
    },
    onSearch: (values: { search: string }) => {
      setSearch(values.search);
      return [];
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
    },
    syncWithLocation: false,
    queryOptions: {
      retry: 0,
    },
  });

  const error = tableData.tableQueryResult?.error;
  const isLoading = tableData.tableQueryResult.isLoading;

  const isError = error?.statusCode && error?.statusCode > 399;

  return (
    <OffersAdminTable isLoading={isLoading} isError={isError} tableData={tableData} />
  );
};
