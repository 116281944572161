import { ImagesFileUploaderProps } from '../images-file-uploader';
import { AdditionalEquipmentGuide } from './additional-equipment-guide';
import { BackSeatViewGuide } from './back-seat-view-guide';
import { CarDetailsGuide } from './car-details-guide';
import { DashboardViewGuide } from './dashboard-view-guide';
import { FrontAngleGuide } from './front-angle-guide';
import { FrontGuide } from './front-guide';
import { FrontSeatViewGuide } from './front-seat-view-guide';
import { GeneralAdvice } from './general-advice';
import { InjuriesGuide } from './injuries-guide';
import { RearAngleGuide } from './rear-angle-guide';
import { RearGuide } from './rear-guide';
import { SideViewGuide } from './side-view-guide';
import { TrunkViewGuide } from './trunk-view-guide';
import { WheelsAndTiresViewGuide } from './wheels-and-tires-view-guide';
import React, { forwardRef, useRef } from 'react';
import { IFileUploaderProps } from '../../../file-uploader';


type CatImageGuideProps = Omit<ImagesFileUploaderProps, 'fileUploader'>;

export const CarImageGuides = forwardRef<IFileUploaderProps, CatImageGuideProps>(({
  folderName,
  formRef,
  isLoading,
}: CatImageGuideProps, ref) => {

  const frontAngleUploader = useRef<IFileUploaderProps>(null);
  const readAngleUploader = useRef<IFileUploaderProps>(null);
  const frontUploader = useRef<IFileUploaderProps>(null);
  const rearUploader = useRef<IFileUploaderProps>(null);
  const sideUploader = useRef<IFileUploaderProps>(null);
  const trunkUploader = useRef<IFileUploaderProps>(null);
  const wheelsUploader = useRef<IFileUploaderProps>(null);
  const frontSeatUploader = useRef<IFileUploaderProps>(null);
  const backSeatUploader = useRef<IFileUploaderProps>(null);
  const dashboardUploader = useRef<IFileUploaderProps>(null);
  const additionalEquipmentUploader = useRef<IFileUploaderProps>(null);
  const carDetailsUploader = useRef<IFileUploaderProps>(null);
  const injuriesUploader = useRef<IFileUploaderProps>(null);
  
  const allUploader = [
    frontAngleUploader,
    readAngleUploader,
    frontUploader,
    rearUploader,
    sideUploader,
    trunkUploader,
    wheelsUploader,
    frontSeatUploader,
    backSeatUploader,
    dashboardUploader,
    additionalEquipmentUploader,
    carDetailsUploader,
    injuriesUploader
  ]

  React.useImperativeHandle(ref, () => ({
    upload() {
      throw new Error('Upload not allowed for combined uppy state');
    },
    clear() {
      return allUploader.flatMap(v => v?.current?.clear());
    },
    getFiles() {
      return allUploader.flatMap(v => v?.current?.getFiles() || []);
    },
    setState() {
      console.error('Set state not allowed for combined uppy state')
    },
  }));
  

  return (
    <>
      <GeneralAdvice />
      <FrontAngleGuide
        formRef={formRef}
        fileUploader={frontAngleUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <RearAngleGuide
        formRef={formRef}
        fileUploader={readAngleUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <FrontGuide
        formRef={formRef}
        fileUploader={frontUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <RearGuide
        formRef={formRef}
        fileUploader={rearUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <SideViewGuide
        formRef={formRef}
        fileUploader={sideUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <TrunkViewGuide
        formRef={formRef}
        fileUploader={trunkUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <WheelsAndTiresViewGuide
        formRef={formRef}
        fileUploader={wheelsUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <FrontSeatViewGuide
        formRef={formRef}
        fileUploader={frontSeatUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <BackSeatViewGuide
        formRef={formRef}
        fileUploader={backSeatUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <DashboardViewGuide
        formRef={formRef}
        fileUploader={dashboardUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <AdditionalEquipmentGuide
        formRef={formRef}
        fileUploader={additionalEquipmentUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <CarDetailsGuide
        formRef={formRef}
        fileUploader={carDetailsUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
      <InjuriesGuide
        formRef={formRef}
        fileUploader={injuriesUploader}
        isLoading={isLoading}
        folderName={folderName}
      />
    </>
  );
});
