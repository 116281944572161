import { Deal } from '../../../../utils/deal-base.dto';
import { Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ModalForm, ProFormDatePicker, ProFormText } from '@ant-design/pro-components';
import React from 'react';
import { EditDeal } from '../../../../provider/dto/partner-deal.dto';
import { useUpdate } from '@refinedev/core';
import { useTranslation } from 'react-i18next';
import { EditDealFormFields } from './edit-deal-form-fields';
import { stringDateToISOString } from '../../../../utils/date';


export interface EditDealFormModalProps {
  deal: Deal;
  onComplete: () => Promise<unknown>;
}

export const EditDealFormModal = ({
  deal,
  onComplete,
                                  }: EditDealFormModalProps) => {
  const { mutateAsync: updateDeal, isLoading: isUpdatingDeal} = useUpdate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return <ModalForm<EditDeal>
    loading={isUpdatingDeal}
    width={700}
    initialValues={{
      vin: deal.vin,
      licensePlate: deal.licensePlate,
      lastInspectionDate: deal.lastInspectionDate ? new Date(deal.lastInspectionDate) : undefined,
      make: deal.make,
      model: deal.model,
      variant: deal.variant,
      year: deal.year + '',
      registration: new Date(deal.registration),
      mileage: Number.parseInt(deal.mileage + ''),
      color: deal.color,
      gear: deal.gear,
      fuel: deal.fuel,
    }}
    title={t('global.edit') + ' Deal'}
    trigger={
      <Button type="primary">
        <EditOutlined />
        {t('global.edit') + ' Deal'}
      </Button>
    }
    form={form}
    autoFocusFirstInput
    modalProps={{
      forceRender: true,
      destroyOnClose: true,
    }}
    onFinish={async (values) => {
      await updateDeal({
        resource: 'deal',
        id: deal.id,
        values: {
          ...values,
          year: values.year + '',
          color: values.color ? values.color : null,
          variant: values.variant ? values.variant : null,
          registration: stringDateToISOString(values.registration + ''),
          lastInspectionDate: values.lastInspectionDate ? stringDateToISOString(values.lastInspectionDate + '') : null,
        },
      });
      await onComplete();
      return true;
    }}
  >
    <ProFormText
      name="licensePlate"
      label={t('common.licencePLate')}
      placeholder={'NK22022'}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />
   <EditDealFormFields/>

    <ProFormDatePicker
      name="lastInspectionDate"
      label={t('common.lastInspectionDate')}
      placeholder={'21-12-1999'}
      fieldProps={{
        format: 'DD-MM-YYYY',
        style: { width: '100%', background: 'white' },
      }}
    />
  </ModalForm>;

};