import { useTranslation } from 'react-i18next';
import { ProFormInstance, ProFormSwitch, ProFormText } from '@ant-design/pro-components';
import React from 'react';
import { RichTextEditor } from '../../../components/rich-text';
import { ImagesFileUploader } from '../../../components/deal-details/upload-images/images-file-uploader';
import { IFileUploaderProps } from '../../../components/file-uploader';


export interface OfferFormFieldsProps {
  formRef: React.MutableRefObject<ProFormInstance | undefined>;
  isLoading: boolean;
  previewImageFileUploader: React.RefObject<IFileUploaderProps>
}

export const OfferFormFields: React.FC<OfferFormFieldsProps> = (
  {formRef, isLoading, previewImageFileUploader}
) => {
  const { t } = useTranslation();

  return <>
    <RichTextEditor
      formRef={formRef}
      formItemProps={{
        label: t('offers.fields.title'),
        name: 'title',
        rules: [
          {
            required: true,
            message: t('global.requiredField'),
          },
        ],
      }}
    />
    <ImagesFileUploader
      name="previewImage"
      label={"Preview image"}
      autoSave={true}
      formRef={formRef}
      uploadTo={'offers'}
      restrictions={
        {
          maxFileSize: 1024 * 1024,
          maxNumberOfFiles: 1,
          allowedFileTypes: ['image/*'],
          minNumberOfFiles: 1,
        }
      }
      isLoading={isLoading}
      fileUploader={previewImageFileUploader}
    />
    <RichTextEditor
      formRef={formRef}
      formItemProps={{
        label: t('offers.fields.serviceShortDescription'),
        name: 'serviceShortDescription',
        rules: [
          {
            required: true,
            message: t('global.requiredField'),
          },
        ],
      }}
    />
    <RichTextEditor
      formRef={formRef}
      formItemProps={{
        label: t('offers.fields.serviceDescription'),
        name: 'serviceDescription',
        rules: [
          {
            required: true,
            message: t('global.requiredField'),
          },
        ],
      }}
    />

    <ProFormText
      name="slug"
      label={t('offers.fields.slug')}
      placeholder={t('offers.fields.slug')}
      rules={[
        { required: true, message: t('global.requiredField') },
        {
          max: 512,
          message: t('global.maxLength', { max: 512 }),
        },
      ]}
    />

    <ProFormSwitch
      name="draft"
      label={t('offers.fields.draft')} />

  </>;

};