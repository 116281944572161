import Skeleton from '@ant-design/pro-skeleton';
import { getDefaultSortOrder, useTableReturnType } from '@refinedev/antd';
import { HttpError, useCreate, useGo } from '@refinedev/core';
import { Button, Divider, Flex, Form, Image, Input, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ErrorComponent } from '../../error/404';
import { debounce } from '../../../utils/debouence';
import { Offer } from '../vo/offers.dto';
import { EditOutlined } from '@ant-design/icons';
import { ModalForm, ProFormInstance } from '@ant-design/pro-components';
import React, { useRef, useState } from 'react';
import { OfferFormFields } from './offer-form-fields';
import { OfferRenderer } from './offer-renderer';
import { RichTextRenderer } from '../../../components/rich-text/rish-text-renderer';
import { dataProvider } from '../../../provider';
import { OfferCard } from '../../../components/offer';
import { IFileUploaderProps } from '../../../components/file-uploader';

const { Search } = Input;

const { Text, Title } = Typography;

type OfferListProps = {
  tableData: useTableReturnType<Offer, HttpError, { search: string }>;
  isLoading: boolean;
  isError?: number | boolean;
};

export const OffersAdminTable = ({ tableData, isLoading, isError }: OfferListProps) => {
  const { searchFormProps, sorters, tableProps } = tableData;
  const { t } = useTranslation();
  const go = useGo();
  const { mutateAsync: createOffer, isLoading: isCreatingOffer } = useCreate<Offer>();
  const [createOfferForm] = Form.useForm();
  const [createOfferFormData, setCreateOfferFormData] = useState<Partial<Offer>>({});
  const formRef = useRef<ProFormInstance>();
  const previewFileUploader = useRef<IFileUploaderProps>(null);

  return (
    <>
      {isLoading ? (
        <Skeleton type="result" />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <Flex
          style={{ maxWidth: 1440, margin: '0 auto', padding: 24 }}
          vertical={true}
          gap={'small'}
          align={'center'}
        >
          <Title level={1}>{t('offers.page.metaTitle')}</Title>

          <Flex
            style={{
              width: '100%',
              maxWidth: 1140,
            }}
            align={'center'}
            wrap={'wrap-reverse'}
            gap={'small'}
            justify={'space-between'}
          >
            <Flex
              style={{
                width: '100%',
              }}
              vertical={false} align={'center'} justify={'space-between'}>

              <Form {...searchFormProps} layout="inline">
                <Form.Item name="search">
                  <Search
                    style={{
                      minWidth: '320px',
                    }}
                    size={'large'}
                    loading={isLoading}
                    onChange={debounce(searchFormProps.form?.submit, 400)}
                    placeholder={t('offers.table.searchBy')}
                  />
                </Form.Item>
              </Form>

              <ModalForm<Offer>
                loading={isCreatingOffer}
                width={'100%'}
                formRef={formRef}
                initialValues={{
                  draft: true,
                }}
                title={t('global.add')}
                onFieldsChange={() => {
                  const fieldsValue = createOfferForm.getFieldsValue(true);
                  const uploadedFile = previewFileUploader?.current?.getFiles()?.[0];

                  setCreateOfferFormData({
                    ...fieldsValue,
                      ...(uploadedFile?.meta?.key ? {
                        previewImage: {
                          type: uploadedFile.meta.type,
                          originalName: uploadedFile.meta.name,
                          urlKey: uploadedFile.meta.key,
                          size: uploadedFile.size
                        }
                      } : {})
                    }
                  );


                }}

                trigger={
                  <Button loading={isCreatingOffer} type="primary">
                    <EditOutlined /> {t('global.add')}
                  </Button>
                }
                style={{
                  width: '100%',
                }}
                form={createOfferForm}
                autoFocusFirstInput
                modalProps={{
                  destroyOnClose: true,
                }}
                onFinish={async (values) => {
                  const uploadedFile = previewFileUploader?.current?.getFiles()?.[0];

                  await createOffer({
                    resource: 'offer',
                    values: {
                      ...values,
                      previewImage: {
                        type: uploadedFile?.meta.type,
                        originalName: uploadedFile?.meta.name,
                        urlKey: uploadedFile?.meta.key,
                        size: uploadedFile?.size
                      },
                      title: JSON.stringify(values.title),
                      serviceShortDescription: JSON.stringify(values.serviceShortDescription),
                      serviceDescription: JSON.stringify(values.serviceDescription),
                    }
                  });
                  return true;
                }}
              >
                <Flex vertical={false} gap={'large'}>
                  <Flex vertical={true} wrap={false} style={{
                    width: '100%',
                  }}>
                    <OfferFormFields
                      isLoading={isCreatingOffer}
                      previewImageFileUploader={previewFileUploader}
                      formRef={formRef} />
                  </Flex>

                  <Divider type="vertical" style={{
                    alignSelf: 'stretch',
                    height: 'unset',
                    borderColor: '#7cb305'
                  }}
                  ></Divider>

                  <Flex style={{
                    width: '100%',
                  }}
                        vertical={true}
                        align={'center'}
                        justify={'start'}
                  >
                    <OfferCard
                      wrapperStyles={{
                        maxWidth: '600px'
                      }}
                      title={createOfferFormData.title}
                      serviceShortDescription={createOfferFormData.serviceShortDescription}
                      previewImage={createOfferFormData.previewImage}
                    >
                    </OfferCard>

                    <div style={{
                      marginTop: '600px'
                    }}>
                      <OfferRenderer offer={createOfferFormData} />
                    </div>

                  </Flex>
                </Flex>

              </ModalForm>

            </Flex>

          </Flex>

          <Table
            {...tableProps}
            scroll={{ x: 1140 }}
            locale={{
              emptyText: t('common.noDataText'),
              filterReset: t('common.reset'),
              filterCheckall: t('common.selectAll'),
            }}
            rowKey="id"
          >
            <Table.Column
              width={100}
              render={(offer: Offer) => <Text>{offer.slug}</Text>}
              title={t('offers.fields.slug')}
              ellipsis={true}
            />

            <Table.Column
              width={100}
              render={(offer: Offer) => <RichTextRenderer content={offer.title}/>}
              title={t('offers.fields.title')}
              ellipsis={true}
            />

            <Table.Column
              width={100}
              render={(offer: Offer) => <RichTextRenderer content={offer.serviceShortDescription}/>}
              title={t('offers.fields.serviceShortDescription')}
              ellipsis={true}
            />

            <Table.Column
              width={100}
              render={(offer: Offer) => <Image src={`${dataProvider.getApiUrl()}/file/offers/download-file/${offer.previewImage.urlKey}`}></Image>}
              title={t('offers.fields.previewImage')}
              ellipsis={true}
            />

            <Table.Column
              width={100}
              dataIndex="createdAt"
              title={t('common.createdAt')}
              sorter
              defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
              showSorterTooltip={false}
              render={(value: string) => (
                <Text>
                  {new Date(value).toLocaleDateString()}{' '}
                  {new Date(value).toLocaleTimeString()}
                </Text>
              )}
            />

          </Table>
        </Flex>
      )}
    </>
  );
};
