import React from 'react';
import { Flex, Image, Typography } from 'antd';


export interface RichTextRendererProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  align?: 'center' | 'start';
}

const { Title } = Typography;

export const RichTextRenderer: React.FC<
  RichTextRendererProps
> = ({ content = { blocks: [] }, align = 'center' }) => {

  const contentJson = content?.blocks ? content : JSON.parse(content);


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const richTextEditorContent = contentJson.blocks.map((block: { id: string; type: string; data: any; }) => {

    const
      {
        id,
        type,
        data,
      } = block;
    switch (type) {
      case 'image':
        return <Image preview={false} key={id} src={data.file.url} alt={data.caption}></Image>;
      case 'paragraph':
        return <p key={id}
                  dangerouslySetInnerHTML={{ __html: data.text }}
        />;
      case 'list': {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const itemsList = data.items.map((item: any) => {
          return <li key={item.content}>{item.content}</li>;
        });

        return data.style === 'unordered'
          ? <ul key={id}>{itemsList}</ul> : <ol key={id}>{itemsList}</ol>;
      }
      case 'header':
        return <Title key={id} level={data.level}>{data.text}</Title>;
    }

  });

  return (<Flex style={{
    width: '100%',
  }} vertical={true} justify={'center'} align={align}>
    {richTextEditorContent}
  </Flex>);


};