import type { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  token: {
    colorPrimaryText: 'rgba(0, 0, 0, 0.85)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
    colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
    colorPrimary: '#5cb1c8',
    colorBgContainer: '#F7F8F9',
    colorBgLayout: '#f5f5f5',
    colorBorderBg: '#E8E9EA',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', \'Arial\', \'Noto Sans\', \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\', \'Noto Color Emoji\'',
  },
  components: {
    Layout: {
      siderBg: '#fff',
    },
    Menu: {
      itemHoverBg: '#f5f5f5',
      itemBg: 'transparent',
      itemColor: '#fff',
      itemSelectedColor: '#fff',
      itemSelectedBg: '#4a7f8c',
      itemHoverColor: '#fff',
    },
    Typography: {
      colorText: 'rgba(0, 0, 0, 0.85)',
      colorTextDescription: 'rgba(0, 0, 0, 0.65)',
      colorTextDisabled: 'rgba(0, 0, 0, 0.45)',
      colorLink: '#5cb1c8',
      colorLinkHover: '#4a9abf',
    },
    Card: {
      colorBgContainer: '#FFFFFF',
      headerBg: '#FAFAFA',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      boxShadowTertiary:
        '0px 1px 2px 0px #00000008,0px 1px 6px -1px #000000050px,2px 4px 0px #00000005',
    },
    Table: {
      colorBgContainer: '#fff',
    },
    Input: {
      colorBgContainer: '#fff',
    },
    InputNumber: {
      colorBgContainer: '#fff',
    },
    Calendar: {
      colorBgContainer: '#FFFFFF',
    },
    Radio: {
      colorBgContainer: '#fff',
    },
    Select: {
      colorBgContainer: '#fff',
    },
  },
};