import {
  ProForm,
  ProFormInstance,
} from '@ant-design/pro-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreate, useGo } from '@refinedev/core';
import { Button, Flex, Form, Typography } from 'antd';
import FileUploader, { IFileUploaderProps } from '../file-uploader';
import { FileUpload } from '../../pages/partner/vo/submission';
import { CreatePartnerDeal } from '../../provider/dto/partner-deal.dto';
import { stringDateToISOString } from '../../utils/date';
import { EditDealFormFields } from '../deal-details/upload-images/edit/edit-deal-form-fields';

const { Title } = Typography;

export const PartnerB2bSubmissionForm: React.FC<{
  organisationId: string;
}> = ({
        organisationId,
      }) => {

  const fileUploader = useRef<IFileUploaderProps>(null);

  const formRef = useRef<
    ProFormInstance<CreatePartnerDeal>
  >();

  const { mutateAsync, isLoading } = useCreate();

  const { t } = useTranslation();

  const go = useGo();

  return <ProForm
    formRef={formRef}
    autoFocusFirstInput
    onFinish={async (values) => {

      const uploadResult = await fileUploader.current!.upload();

      const images: FileUpload[] = uploadResult.successful.map((item) => {
        const meta = item.meta;
        const data = item.data;

        return {
          size: data.size,
          originalName: meta.name,
          type: data.type,
          urlKey: String(meta.key),
        };
      });

      await mutateAsync({
        resource: 'partner-deals/' + organisationId,
        values: {
          ...values,
          year: values.year + '',
          color: values.color ? values.color : undefined,
          variant: values.variant ? values.variant : undefined,
          registration: stringDateToISOString(values.registration + ''),
          images,
        },
      });

      go({
        to: '/partner/' + organisationId,
      });

    }}

    submitter={{
      submitButtonProps: false,
      resetButtonProps: false,
      render: () => [],
    }}
    style={{ maxWidth: 820, margin: '0 auto', padding: 24 }}
  >
    <Title level={1}>{t(`page.partnerCarSubmission.title`)}</Title>
    <Title level={3}>{t(`page.partnerCarSubmission.description`)}</Title>

    <EditDealFormFields/>

    <Form.Item
      name={'images'}
      label={'Uploade bilbilleder (Du skal vælge mindst 5 filer)'}
      rules={[
        { required: true, message: t('global.requiredField') },
      ]}
    >
      <FileUploader
        ref={fileUploader}
        restrictions={
          {
            maxFileSize: 20 * 1024 * 1024,
            maxNumberOfFiles: 100,
            allowedFileTypes: ['image/*'],
            minNumberOfFiles: 5,
          }
        }
        folderName={organisationId}
        disabled={isLoading}
        setFilesData={(v) => {
          formRef.current?.setFieldValue('images', v);
          formRef.current?.validateFields(['images']);
        }}
      />
    </Form.Item>


    <Form.Item>
      <Flex justify={'flex-end'}>
        <Button size={'large'} loading={isLoading} htmlType={'submit'} type={'primary'}
                shape={'round'}>{t('global.send')}</Button>
      </Flex>
    </Form.Item>
  </ProForm>;
};