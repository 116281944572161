import { Restrictions, UploadResult, UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drop-target/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import { Dashboard } from '@uppy/react';
import React, { forwardRef } from 'react';
import { useFileUploader } from '../../hooks/file/use-file-uploader';

interface IFileUploader {
  disabled: boolean;
  restrictions?: Restrictions;
  folderName?: string;
  uploadTo?: 'dealer-car' | 'offers';
  setFilesData: (images: UppyFile[]) => void;
  height?: string;
  width?: string;
  autoSave?: boolean;
}

export interface IFileUploaderProps {
  upload: () => Promise<UploadResult<Record<string, unknown>>>;
  clear: () => void;
  getFiles: () => UppyFile<Record<string, unknown>>[];

  setState(patch: Record<string, unknown>): void;
}

const FileUploader = forwardRef<IFileUploaderProps, IFileUploader>(
  (props, ref) => {
    const { uppyInstance } = useFileUploader({
      filePackage: props.folderName,
      restrictions: props.restrictions,
      uploadTo: props.uploadTo || 'dealer-car',
      autoSave: props.autoSave,
    });

    React.useImperativeHandle(ref, () => ({
      upload() {
        return uppyInstance.upload();
      },
      clear() {
        return uppyInstance.clearUploadedFiles();
      },
      getFiles() {
        return uppyInstance.getFiles();
      },
      setState(patch) {
        const currentState = uppyInstance.getState();
        const newState = { ...currentState, ...patch };
        return uppyInstance.setState(newState);
      },
    }));

    uppyInstance.on('files-added', () => {
      if (!props.autoSave) {
        props.setFilesData(uppyInstance.getFiles());
      }
    });

    uppyInstance.on('complete', () =>
      props.setFilesData(uppyInstance.getFiles()),
    );

    uppyInstance.on('file-removed', () => {
          props.setFilesData(uppyInstance.getFiles());
      },
    );

    return (
      <Dashboard
        width={props.width ?? '100%'}
        height={props.height}
        disabled={props.disabled}
        uppy={uppyInstance}
        locale={{
          strings: {
            uploading: 'Uploader',
            complete: 'Færdig',
            uploadFailed: 'Upload mislykkedes',
            paused: 'Pauser',
            retry: 'Prøv igen',
            cancel: 'Annuller',
            pause: 'Pause',
            resume: 'Genoptag',
            done: 'Udført',
            filesUploadedOfTotal: {
              0: '%{complete} af %{smart_count} fil uploadet',
              1: '%{complete} af %{smart_count} filer uploadet',
            },
            dataUploadedOfTotal: '%{complete} af %{total}',
            xTimeLeft: '%{time} tilbage',
            uploadXFiles: {
              0: 'Upload %{smart_count} fil',
              1: 'Upload %{smart_count} filer',
            },
            uploadXNewFiles: {
              0: 'Upload +%{smart_count} fil',
              1: 'Upload +%{smart_count} filer',
            },
            upload: 'Upload',
            retryUpload: 'Prøv upload igen',
            xMoreFilesAdded: {
              0: '%{smart_count} flere filer tilføjet',
              1: '%{smart_count} flere filer tilføjet',
            },
            showErrorDetails: 'Vis fejldetaljer',
            closeModal: 'Luk Modal',
            addMoreFiles: 'Tilføj flere filer',
            addingMoreFiles: 'Tilføjer flere filer',
            importFrom: 'Importér fra %{name}',
            dashboardWindowTitle: 'Upload af filer',
            dashboardTitle: 'Upload af filer',
            copyLinkToClipboardSuccess: 'Link kopieret til udklipsholderen.',
            copyLinkToClipboardFallback: 'Kopiér URL nedenfor',
            copyLink: 'Kopiér link',
            back: 'Tilbage',
            removeFile: 'Fjern fil',
            editFile: 'Redigér fil',
            editing: 'Redigerer %{file}',
            finishEditingFile: 'Afslut redigering af fil',
            saveChanges: 'Gem ændringer',
            myDevice: 'Min enhed',
            dropHint: 'Slip dine filer her',
            uploadComplete: 'Upload fuldført',
            uploadPaused: 'Upload sat på pause',
            resumeUpload: 'Genoptag upload',
            pauseUpload: 'Pause upload',
            cancelUpload: 'Annullér upload',
            xFilesSelected: {
              0: '%{smart_count} fil valgt',
              1: '%{smart_count} filer valgt',
            },
            uploadingXFiles: {
              0: 'Uploader %{smart_count} fil',
              1: 'Uploader %{smart_count} filer',
            },
            processingXFiles: {
              0: 'Behandler %{smart_count} fil',
              1: 'Behandler %{smart_count} filer',
            },
            poweredBy: '',
            addMore: 'Tilføj flere',
            editFileWithFilename: 'Redigér fil %{file}',
            save: 'Gem',
            dropPasteFiles: 'Slip filer her eller %{browseFiles}',
            dropPasteFolders: 'Slip filer her eller %{browseFolders}',
            dropPasteBoth:
              'Slip filer her, %{browseFiles} eller %{browseFolders}',
            dropPasteImportFiles:
              'Slip filer her, %{browseFiles} eller importér fra:',
            dropPasteImportFolders:
              'Slip filer her, %{browseFolders} eller importér fra:',
            dropPasteImportBoth:
              'Slip filer her, %{browseFiles}, %{browseFolders} eller importér fra:',
            importFiles: 'Importér filer fra:',
            browseFiles: 'gennemse filer',
            browseFolders: 'gennemse mapper',
            recoveredXFiles: {
              0: 'Vi kunne ikke fuldt ud gendanne 1 fil. Vælg den venligst igen og genoptag uploaden.',
              1: 'Vi kunne ikke fuldt ud gendanne %{smart_count} filer. Vælg dem venligst igen og genoptag uploaden.',
            },
            recoveredAllFiles:
              'Vi gendannede alle filer. Du kan nu genoptage uploaden.',
            sessionRestored: 'Sessionen gendannet',
            reSelect: 'Vælg igen',
            missingRequiredMetaFields: {
              0: 'Mangler påkrævet metadatafelt: %{fields}.',
              1: 'Mangler påkrævede metadatafelter: %{fields}.',
            },
          },
        }}
        showRemoveButtonAfterComplete={true}
        hideUploadButton={true}
        hideCancelButton={true}
        hideProgressAfterFinish={true}
        proudlyDisplayPoweredByUppy={false}
        hideRetryButton={true}
      />
    );
  },
);

export default FileUploader;
