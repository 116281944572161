import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';
import rearAngleViewImage1 from '../../../../assets/images/picture-guide/vinkletbagfra-incorrect1.webp';
import rearAngleViewImageOk from '../../../../assets/images/picture-guide/vinkletbagfra-correct.webp';
import rearAngleViewImage2 from '../../../../assets/images/picture-guide/vinkletbagfra-incorrect2.webp';
import {
  ImagesFileUploader,
  ImagesFileUploaderProps,
} from '../images-file-uploader';

const { Title } = Typography;

const advicePoints = [
  'Sørg for, at bilen er midt i billedet.',
  'Sørg for at hele bilen er i billedet.',
  'Find en vinkel, hvor man både kan se siden og bagenden.',
];

export const RearAngleGuide = ({
  folderName,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Vinklet bagfra</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} wrap justify='center' align="center">
          <UploadImagesCard imageSrc={rearAngleViewImage1} color="red" />
          <UploadImagesCard imageSrc={rearAngleViewImage2} color="red" />
          <UploadImagesCard imageSrc={rearAngleViewImageOk} color="green" />

          <ImagesFileUploader
            name="rear-angle"
            autoSave={true}
            formRef={formRef}
            folderName={folderName}
            fileUploader={fileUploader}
            isLoading={isLoading}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
