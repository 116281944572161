import { Card, Flex, Space, Typography, theme } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DealDto } from '../../provider/dto/buyer-submission.dto';
import { formatPriceToDanishLocale } from '../../utils/format-price-to-danish-locale';

const { useToken } = theme;

const gridStyle: React.CSSProperties = {
  width: '33.33%',
  padding: 12,
  textAlign: 'left',
};

const { Text, Title } = Typography;

export const CarInfo: React.FC<DealDto> = ({
                                            fuel,
                                            askingPrice,
                                            gear,
                                            mileage,
                                            make,
                                            model,
                                            year,
                                          }) => {
  const { t } = useTranslation();

  const { token } = useToken();

  return (
    <Flex vertical={true} gap={0} style={{
      width: '100%',
    }}>
      <Flex
        align={'center'}
        justify={'space-between'}
        wrap={true}
      >
        <Title>{make} {model}</Title>
        <Space direction={'horizontal'} size={'small'} align={'start'}>
          <Text type={'secondary'} style={{color: token.colorPrimary}}>
            DKK
          </Text>
          <Title level={4} style={{color: token.colorPrimary}}>
            {formatPriceToDanishLocale(askingPrice)},-
          </Title>
        </Space>
      </Flex>
      <Card>
        <Card.Grid style={gridStyle}>
          <Space direction={'vertical'} size={0}>
            <Text type={'secondary'}>Km.</Text>
            <Text>{mileage?.toLocaleString('da-DK') ?? 'N/A'}</Text>
          </Space>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Space direction={'vertical'} size={0}>
            <Text type={'secondary'}>Modelår
            </Text>
            <Text>{year ?? 'N/A'}</Text>
          </Space>
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Space direction={'vertical'} size={0}>
            <Text type={'secondary'}>Drivmiddel</Text>
            <Text>{fuel ? t(`common.fuelTypes.${fuel}`) : 'N/A'}</Text>
          </Space>
        </Card.Grid>
      </Card>

    </Flex>
  );
};
