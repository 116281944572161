import {
  DownloadOutlined,
  EditOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { ModalForm, ProDescriptions, ProForm, ProFormInstance, useToken } from '@ant-design/pro-components';
import { useCreate, useUpdate } from '@refinedev/core';
import { Button, Flex, Form, Image, Space, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarThumbnail from '../../assets/car-thumbnail.svg';
import { DealStatus, getStatusOrder } from '../../pages/partner/vo/submission';
import { dataProvider } from '../../provider';
import { FileUpload } from '../../provider/dto/file-upload.dto';
import { Deal } from '../../utils/deal-base.dto';
import { IFileUploaderProps } from '../file-uploader';
import { flattenAndTransformFiles } from './upload-images/helpers/flatten-and-transform-files';
import {
  mapCarTireTypeAnswer,
  mapChipTunedAnswer,
  mapDamagedAnswer,
  mapMotorChangeAnswer,
  mapRepaintedAnswer,
  mapServiceInTimeAnswer,
  mapTimeBeltAnswer,
} from './upload-images/helpers/map-to-answer-type';
import { UploadImagesCard } from './upload-images/image-card';
import { ImagesFileUploader } from './upload-images/images-file-uploader';
import { CarUsageDetails } from './upload-images/vo';
import { CarQuestionnaire } from './upload-images/car-questionnaire';
import { mapUsageDetailsFromForm } from './upload-images/vo/map-form-details';

const UPLOAD_IMAGES_RESOURCE = 'deal/provide-additional-images';
const { Title, Text } = Typography;

interface FormDetailsProps {
  deal: Deal | undefined;
  dealId: string | undefined;
  refetchDeal: () => Promise<unknown>;
}

const Detail = ({ value, label }: { value: string; label: string }) => {
  const { token } = useToken();
  return (
    <>
      <Flex wrap gap={10}>
        <Text style={{ color: token.colorTextTertiary }}>{label}</Text>
        <Text>{value}</Text>
      </Flex>
    </>
  );
};

export const FormDetails = ({ deal, dealId, refetchDeal }: FormDetailsProps) => {
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const onDownload = (src: string, fileName: string) => {
    fetch(src)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const fileUploader = useRef<IFileUploaderProps>(null);
  const formRef = useRef<ProFormInstance>();
  const [form] = Form.useForm();
  const [editUsageDetailsForm] = Form.useForm();

  const { mutateAsync, isLoading } = useCreate({
    mutationOptions: {
      onSettled() {
        setIsLoadingData(false);
      },
    },
  });

  const { mutateAsync: updateCarUsageDetails, isLoading: isUpdatingCarUsageDetails } = useUpdate();

  const onFinish = async (values: {
    ['additional-images']: Record<string, unknown>;
  }) => {
    const currentUploader = fileUploader.current;

    if (!currentUploader) {
      return;
    }

    setIsLoadingData(true);
    const transformedFiles = flattenAndTransformFiles(values);

    currentUploader.setState({
      files: transformedFiles,
    });

    const uploadResult = await currentUploader.upload();
    const images: FileUpload[] = uploadResult.successful.map(item => {
      const meta = item.meta;
      const data = item.data;

      return {
        size: data.size,
        originalName: meta.name,
        type: data.type,
        urlKey: String(meta.key),
      };
    });

    await mutateAsync({
      resource: UPLOAD_IMAGES_RESOURCE,
      successNotification() {
        return {
          message: 'Yderligere filer er blevet uploadet',
          type: 'success',
        };
      },
      values: {
        id: dealId,
        images,
      },
    });

    currentUploader.clear();
  };

  const order = getStatusOrder(deal?.status);
  const isDealWithProvidedImages = order >= getStatusOrder(DealStatus.ADVERTISEMENT_CREATED);

  if (!deal) {
    return null;
  }

  return (
    <ProDescriptions column={1} size={'small'}>
      <Detail
        label={t(
          'page.dealDetails.uploadImagesForm.questions.engineHasBeenReplaced.label',
        )}
        value={mapMotorChangeAnswer(
          deal?.usageDetails?.motor?.answer,
          deal?.usageDetails?.motor?.details,
        )}
      />

      <Detail
        label={t(
          'page.dealDetails.uploadImagesForm.questions.repainted.question',
        )}
        value={mapRepaintedAnswer(
          deal?.usageDetails?.repainted?.answer,
          deal?.usageDetails?.repainted?.details,
        )}
      />

      <Detail
        label={t(
          'page.dealDetails.uploadImagesForm.questions.damaged.question',
        )}
        value={mapDamagedAnswer(
          deal?.usageDetails?.damaged?.answer,
          deal?.usageDetails?.damaged?.details,
        )}
      />

      <Detail
        label={t(
          'page.dealDetails.uploadImagesForm.questions.chipTuned.question',
        )}
        value={mapChipTunedAnswer(
          deal?.usageDetails?.chipTuned?.answer,
          deal?.usageDetails?.chipTuned?.details,
        )}
      />

      <Detail
        label={t(
          'page.dealDetails.uploadImagesForm.questions.timingBeltChanged.question',
        )}
        value={mapTimeBeltAnswer(
          deal?.usageDetails?.timeBeltChanged?.answer,
          deal?.usageDetails?.timeBeltChanged?.details,
        )}
      />

      <Detail
        label={t(
          'page.dealDetails.uploadImagesForm.questions.serviceDocumentation.question',
        )}
        value={mapServiceInTimeAnswer(deal?.usageDetails?.serviceInTime)}
      />

      <Detail
        label={t('page.dealDetails.uploadImagesForm.questions.tires.question')}
        value={mapCarTireTypeAnswer(deal?.usageDetails?.tires)}
      />

      {
        isDealWithProvidedImages && <ModalForm<CarUsageDetails>
          loading={isUpdatingCarUsageDetails}
          width={700}
          title={t('global.update')}
          trigger={
            <Button loading={isUpdatingCarUsageDetails} type="primary">
              <EditOutlined /> {t('global.edit')}
            </Button>
          }
          style={{
            width: '100%',
          }}
          form={editUsageDetailsForm}
          autoFocusFirstInput
          modalProps={{
            destroyOnClose: true,
          }}
          onFinish={async (values) => {

            const usageDetails = mapUsageDetailsFromForm(values);

            await updateCarUsageDetails({
              resource: 'deal/car-usage-details',
              id: deal.id,
              values: usageDetails,
            });
            await refetchDeal();
            return true;
          }}
        >
          <CarQuestionnaire
            existingUsageDetails={deal?.usageDetails}
          />
        </ModalForm>
      }

      <Image.PreviewGroup
        preview={{
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: {
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
              },
              current,
            },
          ) => (
            <Space size={14}>
              <DownloadOutlined
                style={{ fontSize: 24 }}
                onClick={() =>
                  onDownload(
                    `${dataProvider.getApiUrl()}/file/dealer-car/download-file/${deal?.images[current]?.urlKey}`,
                    `${deal?.images[current]?.originalName}`,
                  )
                }
              />
              <SwapOutlined
                style={{ fontSize: 24 }}
                rotate={90}
                onClick={onFlipY}
              />
              <SwapOutlined style={{ fontSize: 24 }} onClick={onFlipX} />
              <RotateLeftOutlined
                style={{ fontSize: 24 }}
                onClick={onRotateLeft}
              />
              <RotateRightOutlined
                style={{ fontSize: 24 }}
                onClick={onRotateRight}
              />
              <ZoomOutOutlined
                style={{ fontSize: 24 }}
                disabled={scale === 1}
                onClick={onZoomOut}
              />
              <ZoomInOutlined
                style={{ fontSize: 24 }}
                disabled={scale === 50}
                onClick={onZoomIn}
              />
            </Space>
          ),
        }}
      >
        <Flex
          style={{
            width: '100%',
          }}
          vertical={true}
        >
          <Flex
            vertical={false}
            align={'center'}
            justify={'flex-start'}
            gap={'middle'}
          >
            <Title
              level={1}
              style={{
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              {t('common.images')}
            </Title>
          </Flex>
          <Flex
            align={'flex-start'}
            wrap={true}
            style={{
              paddingTop: 24,
              width: '100%',
            }}
            vertical={false}
            gap={'small'}
          >
            {deal?.images.map((image, index) => {
              const src = `${dataProvider.getApiUrl()}/file/dealer-car/download-file/${image.urlKey}`;
              return (
                <UploadImagesCard
                  key={`${image.originalName}-${image.urlKey}-${index}`}
                  imageSrc={src}
                  noMark
                  preview
                  isSquareShape
                  placeholder={<Image src={CarThumbnail} />}
                />
              );
            })}
          </Flex>
        </Flex>
      </Image.PreviewGroup>

      {isDealWithProvidedImages && <ProForm
        form={form}
        formRef={formRef}
        autoFocusFirstInput
        onFinish={onFinish}
        submitter={{
          submitButtonProps: false,
          resetButtonProps: false,
          render: () => [],
        }}
        style={{
          width: '100%',
          padding: 0,
        }}
      >
        <ImagesFileUploader
          name="additional-images"
          label={`Har du behov for at indsende flere billeder, kan du uploade dem her`}
          formRef={formRef}
          folderName={dealId}
          fileUploader={fileUploader}
          isLoading={isLoading}
          required={false}
          formItemProps={{ layout: 'vertical' }}
        />

        <Button
          loading={isLoading || isLoadingData}
          type="primary"
          htmlType="submit"
        >
          {t('global.send')}
        </Button>
      </ProForm>}
    </ProDescriptions>
  );
};
