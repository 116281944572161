import React from 'react';
import { Flex, Image } from 'antd';
import { FileUpload } from '../../pages/partner/vo/submission';
import styled from 'styled-components';
import { RichTextRenderer } from '../rich-text/rish-text-renderer';
import { RightCircleTwoTone } from '@ant-design/icons';
import { useToken } from '@ant-design/pro-components';
import { dataProvider } from '../../provider';


const CardContainer = styled(Flex)`
    padding: 16px 20px 24px;
    width: 100%;
    min-height: 315px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 16px; /* Inside auto layout */
    
    &:hover {
        transition: all .2s ease-in-out;
        transform: scale(1.03);
        cursor: pointer;
    }
`

export interface OfferCardProps {
  title?: any;
  serviceShortDescription?: any;
  previewImage?: FileUpload;
  wrapperStyles?: React.CSSProperties;

}

const CardImage = styled(Image)`
    width:100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px; /* Inside auto layout */
`

export const OfferCard: React.FC<
  OfferCardProps
> = ({ title, wrapperStyles, serviceShortDescription, previewImage }) => {

  const { token} = useToken();

  return (<CardContainer

    style={wrapperStyles}
    gap={'small'} vertical={true} justify={'space-between'} align={'center'}>

    <Flex vertical={false}
          justify={'space-between'} align={'stretch'}
          style={{
            width: '100%'
          }}
    >
      <RichTextRenderer align={'start'} content={title}/>
      <RightCircleTwoTone style={{ fontSize: '32px' }} twoToneColor={token.colorPrimary} />
    </Flex>
    <CardImage preview={false} src={`${dataProvider.getApiUrl()}/file/offers/download-file/${previewImage?.urlKey}`}></CardImage>
    <RichTextRenderer align={'start'} content={serviceShortDescription}/>
  </CardContainer>)


};