import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';
import trunkViewImage1 from '../../../../assets/images/picture-guide/bagegerum-incorrect1.webp';
import trunkViewImageOk from '../../../../assets/images/picture-guide/bagagerum-correct.webp';
import {
  ImagesFileUploader,
  ImagesFileUploaderProps,
} from '../images-file-uploader';

const { Title } = Typography;

const advicePoints = [
  'Åben bagagerummet.',
  'Sørg for, at man kan se hele åbningen af bagagerummet.',
  'Sørg for, at der er lys nok til, at man kan se dybden af bagagerummet. Tag evt. to billeder for at vise begge vinkler.',
];

export const TrunkViewGuide = ({
  folderName,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Bagagerum</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} wrap justify='center' align="center">
          <UploadImagesCard imageSrc={trunkViewImage1} color="red" />
          <UploadImagesCard imageSrc={trunkViewImageOk} color="green" />
          <ImagesFileUploader
            name="trunk"
            autoSave={true}
            formRef={formRef}
            folderName={folderName}
            fileUploader={fileUploader}
            isLoading={isLoading}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
