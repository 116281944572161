import React from 'react';
import { Offer } from '../vo/offers.dto';
import styled from 'styled-components';
import { Flex } from 'antd';
import { RichTextRenderer } from '../../../components/rich-text/rish-text-renderer';


export interface OfferRendererProps {
  offer: Partial<Offer>;
}

const OfferRendererWrapper = styled.div`
  margin: 16px;
  width: 100%;
  
`
export const OfferRenderer: React.FC<OfferRendererProps> = (
  {
    offer
  }
) => {

  return <OfferRendererWrapper>
    <Flex vertical={true} gap={'small'} justify={'center'} align={'center'}>
      <RichTextRenderer content={offer.serviceDescription}></RichTextRenderer>
    </Flex>

  </OfferRendererWrapper>;

};