import Header from '@editorjs/header';
import List from '@editorjs/list';
import Delimiter from '@editorjs/delimiter';
import React, { memo, useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import styled from 'styled-components';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form/FormItem';
import { ProFormInstance } from '@ant-design/pro-components';
import ColorPicker from 'editorjs-color-picker';
import { ToolConstructable } from '@editorjs/editorjs/types/tools';
import Paragraph from '@editorjs/paragraph';
import ImageTool from '@editorjs/image';
import axios from 'axios';
import { API_URL, dataProvider } from '../../provider';
import FontSizeTool from '../../plugins/editorjs-inline-font-size';


const axiosInstance = axios.create();

export const EDITOR_JS_TOOLS = {
  list: List,
  header: Header,
  fontSize: {
    class: FontSizeTool,
  } as unknown as ToolConstructable,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  } as unknown as ToolConstructable,
  image: {
    class: ImageTool,
    config: {
      uploader: {
        uploadByFile: async (file: File) => {

          try {


            const uploadResponse = await axiosInstance.post(`${API_URL}/file/offers/get-upload-presign-url`,
              {
                filesData: [{
                  originalFileName: file.name,
                }],
              });

            const { key, preSignedUrl } = uploadResponse.data[0];

            await axiosInstance.put(preSignedUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
            });

            return {
              success: 1,
              file: {
                url: `${dataProvider.getApiUrl()}/file/offers/download-file/${key}`,
              },
            };

          } catch (e) {
            return {
              success: 0,
            };
          }
        },
        uploadByUrl: () => {
          alert('Not supported');
        },
      },
    },
  } as unknown as ToolConstructable,
  ColorPicker: {
    class: ColorPicker,
    config: {
      colors: [
        '#5cb1c8',
        '#1f6a83',
        '#206e4e',
        '#e56910',
        '#ae2e24',
        '#5e4db2',
        '#758195',
        '#1e7afd',
        '#2998bd',
        '#23a06b',
        '#fea363',
        '#c9372c',
        '#8270db',
      ],
    },

  } as unknown as ToolConstructable,
  delimiter: Delimiter,
};


export interface RichTextEditorProps {
  formRef: React.MutableRefObject<ProFormInstance | undefined>;
  formItemProps: FormItemProps;
}

const EditorContainer = styled.div`
    width: 100%;

    .codex-editor__redactor {
        border-radius: 6px;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
    }

    .ce-block__content, .ce-toolbar__content {
        max-width: calc(100% - 120px) !important;
    }

    .cdx-block {
        padding: .4em;
        max-width: 100% !important;
    }

    .codex-editor__redactor {
        padding-bottom: 200px !important;
    }

`;

export const RichTextEditor: React.FC<
  RichTextEditorProps
> = ({ formItemProps, formRef }) => {
  const ref = useRef<EditorJS>();

  useEffect(() => {
    //Initialize editorjs if we don't have a reference
    if (!ref.current) {
      const editor = new EditorJS({
        holder: formItemProps.name,
        tools: EDITOR_JS_TOOLS,
        placeholder: formItemProps.label?.toString(),
        data: formItemProps.initialValue || formRef.current?.getFieldValue(formItemProps.name),
        async onChange(api) {
          const data = await api.saver.save();
          formRef.current?.setFieldValue(formItemProps.name, data);
          formRef.current?.validateFields([formItemProps.name]);
        },
      });
      ref.current = editor;
    }

    //Add a return function to handle cleanup
    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
    };
  }, []);
  return <Form.Item
    {...formItemProps}
  >
    <EditorContainer id={formItemProps.name} />
  </Form.Item>;

};

export default memo(RichTextEditor);