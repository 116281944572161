import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';
import extra1 from '../../../../assets/images/picture-guide/ekstra-correct1.webp';
import extra2 from '../../../../assets/images/picture-guide/ekstra-correct2.webp';
import extra3 from '../../../../assets/images/picture-guide/ekstra-correct3.webp';
import extra4 from '../../../../assets/images/picture-guide/ekstra-correct4.webp';
import { ImagesFileUploader, ImagesFileUploaderProps } from '../images-file-uploader';

const { Title } = Typography;

const advicePoints = [
  'Hvis din bil har ekstraudstyr såsom navigation, fartpilot, bakkamera, CarPlay, soltag, specialfælge og lignende, så tag et nærbillede af det.',
  'Sørg for at tage billedet tæt på, så man kan se, hvad det er.',
  'Hvis det er en funktion på skærmen, så sørg for, at den er valgt og aktiv.',
];

const images = [extra1, extra2, extra3, extra4];

export const AdditionalEquipmentGuide = ({
  folderName,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Ekstraudstyr</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} wrap justify='center' align="center">
          <Space size={'middle'} style={{ justifyContent: 'center' }} wrap>
            {images.map((image, index) => (
              <UploadImagesCard
                key={index}
                imageSrc={image}
                color="green"
              />
            ))}
          </Space>
          <ImagesFileUploader
            name='equipment'
            formRef={formRef}
            folderName={folderName}
            autoSave={true}
            fileUploader={fileUploader}
            isLoading={isLoading}
            required={false}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
