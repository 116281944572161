import dayjs from 'dayjs';

export function formatDate(date?: Date | string): string {
  return date ? dayjs(date).format('DD-MM-YYYY') : 'N/A';
}

export function stringDateToISOString(date?: string): string {
  if (!date) {
    return '';
  }
  return new Date(date.split('-').reverse().join('-')).toISOString();
}

export const disableFutureDate = (current: dayjs.Dayjs | null): boolean => {
  return !!current && current.isAfter(dayjs().endOf('day'));
};