import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';
import sideViewImage1 from '../../../../assets/images/picture-guide/side-incorrect1.webp';
import sideViewImageOk from '../../../../assets/images/picture-guide/side-correct.webp';
import sideViewImage2 from '../../../../assets/images/picture-guide/side-incorrect2.webp';
import {
  ImagesFileUploader,
  ImagesFileUploaderProps,
} from '../images-file-uploader';

const { Title } = Typography;

const advicePoints = [
  'Sørg for, at bilen er midt i billedet.',
  'Find en vinkel, hvor man kan se hele siden, men hverken taget eller undervognen.',
  'Tag et billede af både højre og venstre side',
];

export const SideViewGuide = ({
  folderName,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Sider</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} wrap justify='center' align="center">
          <UploadImagesCard imageSrc={sideViewImage1} color="red" />
          <UploadImagesCard imageSrc={sideViewImage2} color="red" />
          <UploadImagesCard imageSrc={sideViewImageOk} color="green" />
          <ImagesFileUploader
            name="side"
            autoSave={true}
            formRef={formRef}
            folderName={folderName}
            fileUploader={fileUploader}
            isLoading={isLoading}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
